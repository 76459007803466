import React from "react";

export class AddArticleRow extends React.Component<{ onChange: () => void}> {
    render() {
        return <tr>
            <td>
                <button onClick={() => this.props.onChange()}>Ny artikkel</button>
            </td>
        </tr>;
    }
}