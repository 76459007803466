import React from "react";
import "../css/Dropdown.css";

type DropdownProps = {
    options?: string[];
    value: string;
    onChange: (value: string) => void;
    noSuggest?: boolean;
}

type DropdownState = {
    query: string;
    is_open: boolean;
}

export default class Dropdown extends React.Component<DropdownProps, DropdownState> {
    constructor(props: DropdownProps) {
        super(props);

        this.state = {
            query: "",
            is_open: false
        }
    }

    query_results = () => {
        if (this.props.options === undefined)
            return []
        return this.props.options
            .filter(option => option.toLowerCase().includes(this.state.query.toLowerCase()));
    }

    onValueClicked = (_: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        console.log("CLICKED");
        this.setState({is_open: !this.state.is_open});
    }

    onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({query: event.target.value});
    }

    onQuerySubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let value = (this.state.query ? (this.query_results()[0] || this.state.query) : "") || "";

        this.props.onChange(value);

        this.setState({is_open: false, query: ""});
    }

    onDropdownChoiceClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const value = e.currentTarget.getAttribute("data-value");

        this.setState({is_open: false});

        this.props.onChange(value || "");
    }

    onDropdownBlur = (_: React.FocusEvent<HTMLDivElement>) => {
        this.setState({is_open: false});
    }

    render() {
        let search_results = this.props.noSuggest ? [] : this.query_results()
            .map((option, index) =>
                <li key={option}
                    className={"dropdown-choice-item" + (index === 0 ? " dropdown-choice-item-selected" : "")}
                    data-value={option}
                    onMouseDown={this.onDropdownChoiceClick}>{option}
                </li>
            );
        return <div className="dropdown" onBlur={this.onDropdownBlur}>
            <span className="dropdown-current-value" onClick={this.onValueClicked}>{this.props.value ? this.props.value : "________"}</span>

            {
                this.state.is_open && <div className="dropdown-container">
                    <div className="dropdown-dropdown">
                        <ul className="dropdown-choice-picker">
                            <li
                                className={"dropdown-choice-item" + (search_results.length === 0 ? " dropdown-choice-item-selected" : "")}
                                data-value={this.state.query}
                                onClick={this.onDropdownChoiceClick}
                            >
                                <form action="#" onSubmit={this.onQuerySubmit}>
                                    <input autoFocus className="dropdown-choice-input" value={this.state.query} onChange={this.onQueryChange}/>
                                </form>
                            </li>

                            { search_results }
                        </ul>
                    </div>
                </div>
            }
        </div>;
    }
}