import { Article } from "../components/ArticleRow";
const prod = process.env.NODE_ENV !== "dev";

const api_origin = prod ? "/api" : window.location.origin.replace("3000", "8000");

export async function get_autocomplete_data() {
    let resp = await fetch(`${api_origin}/autocomplete`, {});
    if (resp.status !== 200) {
        throw new Error(`Failed to get autocomplete data: ${resp.status}`);
    }
    let data = resp.json();

    return data;
}

export async function get_articles() {
    let resp = await fetch(`${api_origin}/articles`, {});
    if (resp.status !== 200) {
        throw new Error(`Failed to get articles: ${resp.status}`);
    }
    let data = await resp.json();
    return data;
}

export async function new_article(): Promise<Article> {
    let resp = await fetch(`${api_origin}/articles`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        }
    });
    if (resp.status !== 200) {
        throw new Error(`Failed to update article: ${resp.status}`);
    }

    return await resp.json() as Article;
}

export async function delete_article(id: string) {
    let resp = await fetch(`${api_origin}/articles/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        }
    });
    if (resp.status !== 200) {
        throw new Error(`Failed to delete article: ${resp.status}`);
    }
}

export async function update_article(article: Article) {
    let resp = await fetch(`${api_origin}/articles`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(article),
    });
    if (resp.status !== 200) {
        throw new Error(`Failed to update article: ${resp.status}`);
    }
}
