import React from "react";
import "../css/DatePicker.css"

type DatePickerProps = {
    onChange: (date: Date) => void;
    value?: string;
}

type DatePickerState = {
    value?: Date;
}

export default class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    private inputRef = React.createRef<HTMLInputElement>();

    constructor(props: DatePickerProps) {
        super(props);

        this.state = {
            value: props.value === undefined ? undefined : new Date(props.value)
        }
    }

    onClick = () => {
        if (this.inputRef.current !== null) {
            this.inputRef.current.focus();
        }
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.valueAsDate && !isNaN(e.target.valueAsDate.getDate())) {
            if (e.target.valueAsDate.getFullYear() > 1900) {
                this.props.onChange(e.target.valueAsDate);
                this.setState({value: e.target.valueAsDate});
            } else {
                this.setState({value: e.target.valueAsDate});
            }
        }
    }

    render() {
        const time = this.state.value && !isNaN(this.state.value.getDate()) ? this.state.value.toISOString().split("T")[0] : "";
        // Should turn yellow if there are three days or less left and red if it's overdue
        const days_left = this.state.value ? Math.floor((this.state.value.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : NaN;
        const color = isNaN(days_left) ? "white" : (days_left < 0 ? "red" : days_left < 4 ? "yellow" : "white");
        // Use a shadow to color the input field
        console.log(`COLOR: ${color}`);
        return <div className="date-picker" onClick={this.onClick} style={{boxShadow: `0 0 0 5px ${color}`}}>
            <input type="date" ref={this.inputRef} onChange={this.onChange} value={time}  />
        </div>;
    }
}