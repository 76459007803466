import React from "react";

type CheckboxProps = {
    value?: boolean;
    onChange: (checked: boolean) => void;
}

export default class Checkbox extends React.Component<CheckboxProps> {
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(e.target.checked);
    }

    render() {
        return <div className="checkbox">
            <input type="checkbox" checked={this.props.value} onChange={this.onChange}/>
        </div>;
    }
}

