import React from 'react';
import ArticleRow, {Article} from "./ArticleRow";
import Autocomplete from './Autocomplete';
import * as api from "../util/api";
import "../css/Table.css";
import {AddArticleRow} from "./AddArticleRow";

type AppState = {
    articles: Article[];
    sort_by: string;
    sort_order: number;
    autocomplete: any
}

export default class ArticleTable extends React.Component<any, AppState> {
    constructor(props: any) {
        super(props);

        this.state = {
            articles: [],
            sort_by: "artikkelnavn",
            sort_order: 1,
            autocomplete: {}
        }
    }

    componentDidMount() {
        api.get_articles().then((data: Article[]) => {
            this.setState({
                articles: data
            });
        });

        this.refresh_autocomplete();
    }

    refresh_autocomplete() {
        api.get_autocomplete_data().then(data => {
            if (data !== this.state.autocomplete) {
                this.setState({
                    autocomplete: data
                });
            }
        });
    }

    newArticle = () => {
        api.new_article().then(article => {
            this.setState({
                articles: [article, ...this.state.articles]
            });
        });
    }

    deleteArticle = (article: Article) => {
        api.delete_article(article._id).then(() => {
            this.setState({
                articles: this.state.articles.filter(a => a._id !== article._id)
            });
        });
    }

    onArticleUpdate = (article: Article) => {
        api.update_article(article).then(() => {
            console.log(article);
            this.refresh_autocomplete();
        });


        this.setState({
            articles: this.state.articles.map(a => a._id === article._id ? article : a)
        })
    }

    sortBy = (key: string) => {
        let sort_order = this.state.sort_by === key ? this.state.sort_order * -1 : 1;

        this.setState({
            sort_by: key,
            sort_order: sort_order
        });
    }

    render() {
        console.log(this.state.articles);
        return <table>
            <thead>
                <tr>
                    { /*
                    export type Article = {
                    artikkelnavn: string;
                    kategori: string;
                    prioritet: string;
                    forfatter: string;
                    redaktør: string;
                    frist_forfatter: string;
                    levert_tekst: string;
                    fagkonsulent: string;
                    levering_fagkonsulent: string;
                    tekst_til_korrektur: string;
                    frist_korrektur: string;
                    korrekturlest: string;
                    skal_ha_bilder: string;
                    type_bilder: string;
                    illustratør: string;
                    frist_illustratør: string;
                    ferdig_tekst: string;
                    skal_artikkel_prøves: boolean;
                    nynorsk: boolean;
                    merknader: string;
                    _id: string;
                }
                    */ }
                    <th onClick={() => this.sortBy("kategori")}>Kategori</th>
                    <th onClick={() => this.sortBy("artikkelnavn")}>Artikkelnavn</th>
                    <th onClick={() => this.sortBy("prioritet")}>Prioritet</th>
                    <th onClick={() => this.sortBy("forfatter")}>Forfatter</th>
                    <th onClick={() => this.sortBy("redaktør")}>Redaktør</th>
                    <th onClick={() => this.sortBy("frist_forfatter")}>Frist forfatter</th>
                    <th onClick={() => this.sortBy("levert_tekst")}>Levert tekst</th>
                    <th onClick={() => this.sortBy("fagkonsulent")}>Fagkonsulent</th>
                    <th onClick={() => this.sortBy("levering_fagkonsulent")}>Levering fagkonsulent</th>
                    <th onClick={() => this.sortBy("tekst_til_korrektur")}>Tekst til korrektur</th>
                    <th onClick={() => this.sortBy("frist_korrektur")}>Frist korrektur</th>
                    <th onClick={() => this.sortBy("korrekturlest")}>Korrekturlest</th>
                    <th onClick={() => this.sortBy("skal_ha_bilder")}>Skal ha bilder</th>
                    <th onClick={() => this.sortBy("type_bilder")}>Type bilder</th>
                    <th onClick={() => this.sortBy("illustratør")}>Illustratør</th>
                    <th onClick={() => this.sortBy("frist_illustratør")}>Frist illustratør</th>
                    <th onClick={() => this.sortBy("ferdig_tekst")}>Ferdig tekst</th>
                    <th onClick={() => this.sortBy("skal_artikkel_prøves")}>Skal artikkel prøves</th>
                    <th onClick={() => this.sortBy("nynorsk")}>Nynorsk</th>
                    <th onClick={() => this.sortBy("merknader")}>Merknader</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.articles
                        .sort((a, b) => {
                            // @ts-ignore
                            const val_a: any = a[this.state.sort_by];
                            // @ts-ignore
                            const val_b: any = b[this.state.sort_by];
                            return (val_a > val_b ? 1 : -1) * this.state.sort_order;
                        })
                        .map((article: Article) => {
                        return <ArticleRow autocomplete={this.state.autocomplete} key={article.artikkelnavn} article={article} onChange={this.onArticleUpdate} deleteMe={this.deleteArticle}/>
                    })
                }
                <AddArticleRow onChange={this.newArticle}/>
            </tbody>
        </table>;
    }
}
