import React from "react";
import DropdownChoice from "./DropdownChoice";
import {AutocompleteContext} from "./Autocomplete";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import {Button} from "./Button";

export type Article = {
    artikkelnavn: string;
    kategori: string;
    prioritet: string;
    forfatter: string;
    redaktør: string;
    frist_forfatter: string;
    levert_tekst: string;
    fagkonsulent: string;
    levering_fagkonsulent: string;
    tekst_til_korrektur: string;
    frist_korrektur: string;
    korrekturlest: boolean;
    skal_ha_bilder: boolean;
    type_bilder: string;
    illustratør: string;
    frist_illustratør: string;
    ferdig_tekst: string;
    skal_artikkel_prøves: boolean;
    nynorsk: boolean;
    merknader: string;
    _id: string;
}

type ArticleRowProps = {
    article: Article,
    onChange: (article: Article) => void;
    deleteMe?: (article: Article) => void;
    autocomplete: any
}

export default class ArticleRow extends React.Component<ArticleRowProps> {
    onChange = (key: string, value: any) => {
        let article = { ...this.props.article };
        // @ts-ignore
        article[key] = value;
        this.props.onChange(article);
    }

    deleteRow = () => {
        // eslint-disable-next-line no-restricted-globals
        if (this.props.deleteMe && confirm("Er du sikker på at du vil slette denne artikkelen?")) {
            this.props.deleteMe(this.props.article);
        }
    }

    render() {
        return <tr>
            <td><DropdownChoice
                options={this.props.autocomplete["kategori"]}
                value={this.props.article.kategori}
                onChange={value => this.onChange("kategori", value)}/></td>
            <td>
                <DropdownChoice
                    options={this.props.autocomplete["artikkelnavn"]}
                    value={this.props.article.artikkelnavn}
                    onChange={value => this.onChange("artikkelnavn", value)}
                    noSuggest/></td>
            <td><DropdownChoice
                options={["1", "2", "3"]}
                value={this.props.article.prioritet}
                onChange={value => this.onChange("prioritet", value)}/></td>
            <td><DropdownChoice
                options={this.props.autocomplete["forfatter"]}
                value={this.props.article.forfatter}
                onChange={value => this.onChange("forfatter", value)}/></td>
            <td><DropdownChoice
                options={this.props.autocomplete["redaktør"]}
                value={this.props.article.redaktør}
                onChange={value => this.onChange("redaktør", value)}/></td>
            <td><DatePicker
                value={this.props.article.frist_forfatter}
                onChange={value => this.onChange("frist_forfatter", value)}/></td>
            <td><DropdownChoice
                options={this.props.autocomplete["levert_tekst"]}
                value={this.props.article.levert_tekst}
                onChange={value => this.onChange("levert_tekst", value)}/></td>
            <td><DropdownChoice
                options={this.props.autocomplete["fagkonsulent"]}
                value={this.props.article.fagkonsulent}
                onChange={value => this.onChange("fagkonsulent", value)}/></td>
            <td><DatePicker
                value={this.props.article.levering_fagkonsulent}
                onChange={value => this.onChange("levering_fagkonsulent", value)}/></td>
            <td><DatePicker
                value={this.props.article.tekst_til_korrektur}
                onChange={value => this.onChange("tekst_til_korrektur", value)}/></td>
            <td><DatePicker
                value={this.props.article.frist_korrektur}
                onChange={value => this.onChange("frist_korrektur", value)}/></td>
            <td><Checkbox
                value={this.props.article.korrekturlest}
                onChange={value => this.onChange("korrekturlest", value)}/></td>
            <td><Checkbox
                value={this.props.article.skal_ha_bilder}
                onChange={value => this.onChange("skal_ha_bilder", value)}/></td>
            <td><DropdownChoice
                options={this.props.autocomplete["type_bilder"]}
                value={this.props.article.type_bilder}
                onChange={value => this.onChange("type_bilder", value)}/></td>
            <td><DropdownChoice
                options={this.props.autocomplete["illustratør"]}
                value={this.props.article.illustratør}
                onChange={value => this.onChange("illustratør", value)}/></td>
            <td><DatePicker
                value={this.props.article.frist_illustratør}
                onChange={value => this.onChange("frist_illustratør", value)}/></td>
            <td><DropdownChoice
                noSuggest
                value={this.props.article.ferdig_tekst}
                onChange={value => this.onChange("ferdig_tekst", value)}/></td>
            <td><Checkbox
                value={this.props.article.skal_artikkel_prøves}
                onChange={value => this.onChange("skal_artikkel_prøves", value)}/></td>
            <td><Checkbox
                value={this.props.article.nynorsk}
                onChange={value => this.onChange("nynorsk", value)}/></td>
            <td><DropdownChoice
                options={this.props.autocomplete["merknader"]}
                value={this.props.article.merknader}
                onChange={value => this.onChange("merknader", value)}/></td>
            <td><button onClick={this.deleteRow}>Slett</button></td>
        </tr>;
    }
}
