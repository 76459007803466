import React from "react";
import ArticleTable from "./ArticleTable";
import "../css/App.css";

export default class App extends React.Component {
    render() {
        return <div>
            <h1>Lille norske leksikon</h1>
            <ArticleTable/>
        </div>;
    }
}